import { BrowserRouter as Router, Route, Routes, useRoutes } from 'react-router-dom';
import './App.css';
import React, { useContext, lazy, Suspense } from 'react';
import { AuthProvider, AuthContext } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import Base from './Base';
import AdminBase from './AdminBase';
import Signin from './Admin/Signin';


const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/signin", element: <Signin /> },
    { path: "/*", element: <Base /> },
    { path: "/admin/*", element: <PrivateRoute><AdminBase /></PrivateRoute> },

    // ... other main routes
  ]);

  return routes;
}



function App() {
  // const { isCheckingAuth } = useContext(AuthContext);

  // if (isCheckingAuth) {
  //   return <h1>Loading...</h1>;
  // }

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
