import React, { useEffect, useState } from 'react';
import axios from 'axios';


const initialTabs = [
    { name: 'Recommended', href: '#', current: true, url_join: 'get_articles_recommended' },
    { name: 'Lifestyle', href: '#', current: false, url_join: 'get_articles_lifestyle' },
    { name: 'Tech', href: '#', current: false, url_join: 'get_articles_tech' },
    { name: 'Entertainment', href: '#', current: false, url_join: 'get_articles_entertainment' },
    { name: 'Business and Finance', href: '#', current: false, url_join: 'get_articles_business_and_finance' },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

// BlogTabs component
function BlogTabs({ onSelectTab }) {
    const [tabs, setTabs] = useState(initialTabs);

    // Function to handle tab click
    const selectTab = (selectedTab) => {
        // Create a new tab array with the selected tab marked as current
        const newTabs = tabs.map((tab) => ({
            ...tab,
            current: tab.name === selectedTab.name,
        }));
        onSelectTab(selectedTab);

        // Update the tabs state
        setTabs(newTabs);

        // Optional: Do something else when a tab is selected, e.g., load new content
    };

    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) => selectTab(tabs.find((tab) => tab.name === e.target.value))}
                    value={tabs.find((tab) => tab.current).name}
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                            <button
                                key={tab.name}
                                onClick={() => selectTab(tab)}
                                className={classNames(
                                    tab.current
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                )}
                                aria-current={tab.current ? 'page' : undefined}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default function MainBlog() {

    const [posts, setPosts] = useState([]);
    const [selectedTab, setSelectedTab] = useState(initialTabs[0]);
    const baseURL = process.env.REACT_APP_BASE_URL

    // useEffect(() => {
    //     axios.get('http://localhost:8000/articles/api/allarticles')
    //         .then(res => {
    //             console.log(res.data);
    //             setPosts(res.data);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }, [])

    useEffect(() => {
        // Fetch data based on selectedTab
        const fetchData = async () => {
            try {
                const url = `${baseURL}/articles/api/${selectedTab.url_join}`; // Constructing URL using url_join
                const response = await axios.get(url);
                setPosts(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, [selectedTab]);

    const handleSelectTab = (tab) => {
        setSelectedTab(tab);
    };


    return (
        <div className="bg-white py-2 sm:py-2">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Welcome to the blog</h2>
                    <p className="mt-2 text-lg leading-8 text-gray-600">
                        Unleashing a Universe of Narratives.
                    </p>
                    <br />
                    <BlogTabs onSelectTab={handleSelectTab} />
                    {/* <div className="mt-10 space-y-16 pt-10 sm:mt-16 sm:pt-16"> */}
                    <div className="mt-6 space-y-16 sm:space-y-16">
                        {posts.map((post) => (
                            <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
                                <div className="flex items-center gap-x-4 text-xs">
                                    <time dateTime={post.datetime} className="text-gray-500">
                                        {post.date}
                                    </time>
                                    <a
                                        href={post.category.href}
                                        className="relative z-10 rounded-full bg-gray-50 px-3 py-1 font-medium text-gray-600 hover:bg-gray-100"
                                    >
                                        {post.category.title}
                                    </a>
                                </div>
                                <div className="group relative">
                                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                        <a href={post.href}>
                                            <span className="absolute inset-0" />
                                            {post.title}
                                        </a>
                                    </h3>
                                    <p className="mt-2 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                                </div>
                                <div className="relative mt-3 flex items-center gap-x-4">
                                    <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                                    <div className="text-sm leading-6">
                                        <p className="font-semibold text-gray-900">
                                            <a href={post.author.href}>
                                                <span className="absolute inset-0" />
                                                {post.author.name}
                                            </a>
                                        </p>
                                        <p className="text-gray-600">{post.author.role}</p>
                                    </div>
                                </div>
                            </article>
                        ))}
                    </div>
                </div>
            </div>
        </div >
    )
}
