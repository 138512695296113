import { EnvelopeIcon, PhoneIcon, PencilIcon, RssIcon } from '@heroicons/react/20/solid'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';


export default function ArticleCard({ posts }) {

    return (
        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {posts.map((post) => (
                <li key={post.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                    <div className="flex w-full items-center justify-between space-x-6 p-6">
                        <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                                <h3 className="truncate text-sm font-medium text-gray-900">{post.title}</h3>
                                <span
                                    className={`inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ${post.status === 'draft' ? 'bg-red-50 text-red-700 ring-red-600/20'
                                        : 'bg-green-50 text-green-700 ring-green-600/20'
                                        }`}
                                >
                                    {post.category.title}
                                </span>
                            </div>
                            <p className="mt-1 truncate text-sm text-gray-500">{post.description}</p>
                        </div>
                        <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" src={post.author.imageUrl} alt="" />
                    </div>
                    <div>
                        <div className="-mt-px flex divide-x divide-gray-200">
                            <div className="flex w-0 flex-1">
                                <a
                                    href={`mailto:${post.href}`}
                                    className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                >
                                    <RssIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Open Post
                                </a>
                            </div>
                            <div className="-ml-px flex w-0 flex-1">
                                <Link
                                    to={`/admin/edit/${post.id}`}
                                    className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                >
                                    <PencilIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Edit Post
                                </Link>
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    )
}
