import ArticleAuthor from "../Components/ArticleAuthor";
import ArticleRecommend from "../Components/ArticleRecommend";
import CT1 from "../Components/Content1";
import Footer from "../Components/Footer";

export default function CP1() {
    return (
        <div className="flex flex-col justify-center items-center min-h-screen">
            <CT1 />
            <ArticleAuthor />
            <ArticleRecommend />
            <Footer />
        </div>
    )
}
