
import Footer from '../Components/Footer'
import StartWriting from '../Components/StartWriting'
import AuthorRecommend from '../Components/AuthorRecommend'
import MainBlog from '../Components/MainBlog'

export default function HomePage() {
    return (
        <>
            <aside className="sticky top-8 hidden w-44 shrink-0 lg:block">{/* Left column area */}</aside>

            <main className="flex-1"> <MainBlog /> </main>

            <aside className="sticky top-8 hidden w-96 shrink-0 xl:block">
                <StartWriting />
                <br />
                <AuthorRecommend />
                <Footer />
            </aside>
        </>
    )
}