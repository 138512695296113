import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

export default function ContentRenderer({ content, title }) {
    const { slug } = useParams(); // This is how you access the slug in the URL
    const [article, setArticle] = useState(null);
    const [blocks, setBlocks] = useState([]);
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        // Function to fetch article data
        const fetchArticleData = async () => {
            try {
                const response = await axios.get(`${baseURL}/articles/${slug}/`);
                setArticle(response.data.article);
                setBlocks(response.data.blocks);
                console.log(response.data.article);
            } catch (error) {
                console.error("Error fetching article data:", error);
            }
        };

        fetchArticleData();
    }, [slug]); // This effect runs when the slug changes

    if (!article) {
        return <div>Loading...</div>;
    }


    return (
        <div className="bg-white px-6 py-4 lg:px-8">
            <Helmet>
                <title>{article.title}</title>
                <meta name="description" content={article.metadescription} />
            </Helmet>
            <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                <p className="text-base font-semibold leading-7 text-indigo-600">Introducing</p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{article.title}</h1>
                <p className="mt-6 text-xl leading-8">
                    {article.metadescription}
                </p>

                {blocks.map((block, index) => {
                    switch (block.block_type) {
                        case 'paragraph':
                            return <p key={index} className="mt-8">{block.content}</p>;
                        case 'heading':
                            return <h2 key={index} className="mt-16 text-2xl font-bold tracking-tight text-gray-900">{block.content}</h2>;
                        case 'image':
                            return <figure key={index} className="mt-16">
                                <img
                                    className="aspect-video rounded-xl bg-gray-50 object-cover"
                                    src={block.image}
                                    alt=""
                                />
                                <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
                                    <InformationCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-300" aria-hidden="true" />
                                    {block.caption}
                                </figcaption>
                            </figure>;
                        // Add more cases for different block types
                        default:
                            return <div key={index}>{block.content}</div>;
                    }
                })}
            </div>
        </div>
    )
}
