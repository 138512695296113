import axios from "axios"
import React, { useEffect, useState } from "react"
import ArticleCard from "../Components/ArticleCard"


export default function DraftArticle() {
    const [posts, setPosts] = useState([])
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        axios.get(`${baseURL}/articles/api/get_draftarticles`)
            .then(res => {
                console.log(res.data)
                setPosts(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])


    return (
        <div>
            <ArticleCard posts={posts} />

        </div>
    )
}