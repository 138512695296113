import React, { useState, useEffect } from 'react';
import ArticleCard from "../Components/ArticleCard";
import axios from 'axios';
import ArticleFilters from '../Components/ArticleFilters';


export default function CreateArticle() {
    // State to manage if the popup is open
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [authors, setAuthors] = useState([]);

    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [author, setAuthor] = useState('');
    const [posts, setPosts] = useState([])
    const baseURL = process.env.REACT_APP_BASE_URL;


    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    const handleAuthorChange = (e) => {
        setAuthor(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(`${baseURL}/articles/api/creation_request`, {
                title,
                category,
                author,
            }, {
                headers: {
                    Authorization: `Token ${token}`
                }
            });

            console.log('Form submitted successfully:', response.data);
            setIsPopupOpen(false)
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle errors (e.g., show error message)
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token'); // Replace 'your_token_key' with the key you use in localStorage
        const fetchCategoriesAndAuthors = async () => {
            try {
                const response = await axios.get(`${baseURL}/articles/api/categories_authors`, {
                    headers: {
                        Authorization: `Token ${token}` // Assuming the token is a Bearer token
                    }
                });
                setCategories(response.data.categories);
                setAuthors(response.data.authors);
            } catch (error) {
                console.error('Error fetching categories and authors:', error);
            }
        };

        fetchCategoriesAndAuthors();
    }, []);


    useEffect(() => {
        axios.get(`${baseURL}/articles/api/allarticles`)
            .then(res => {
                console.log(res.data)
                setPosts(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])




    return (
        <div className="flex flex-col items-center">

            {/* Button aligned to the right */}
            <div className="self-end mt-4 mr-4">
                <button
                    type='button'
                    className="rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setIsPopupOpen(true)} // Set isPopupOpen to true when button is clicked
                >
                    Create New Article
                </button>
            </div>

            {/* ArticleCard components */}
            <br />
            {/* <ArticleFilters />
            <br /><br /> */}
            <div className="w-full flex flex-wrap justify-center gap-4">

                <ArticleCard posts={posts} />
                {/* ... other cards */}
            </div>

            {/* Popup Form */}
            {isPopupOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <div className="mt-3 text-center">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                {/* Icon or image for the form */}
                            </div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Article Creation</h3>
                            {/* The form itself */}
                            <br />
                            <form className="mt-2" onSubmit={handleSubmit}>
                                {/* Form fields go here */}
                                <div className="relative">
                                    <label
                                        htmlFor="title"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                                    >
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={title}
                                        onChange={handleTitleChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Best Article Ever!"
                                    />
                                </div>

                                <br />
                                <div className="relative">
                                    <label htmlFor="category"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
                                        Category
                                    </label>
                                    <select
                                        id="category"
                                        name="category"
                                        value={category}
                                        onChange={handleCategoryChange}
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option value="">Select a Category</option>
                                        {categories.map(category => (
                                            <option key={category.id} value={category.name}>{category.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <br />
                                <div className="relative">
                                    <label htmlFor="author"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
                                        Author
                                    </label>
                                    <select
                                        id="author"
                                        name="author"
                                        value={author}
                                        onChange={handleAuthorChange}
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option value="">Select an Author</option>
                                        {authors.map(author => (
                                            <option key={author.id} value={author.name}>{author.name}</option>
                                        ))}
                                    </select>
                                </div>
                                {/* Add more inputs as needed */}
                                <br />
                                <div className="items-center px-4 py-3">
                                    <button
                                        id="ok-btn"
                                        type="submit"  // This is important for form submission
                                        className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                            <div className="items-center px-4 py-3">
                                <button
                                    className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                                    onClick={() => setIsPopupOpen(false)} // Set isPopupOpen to false when button is clicked
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
