// AuthContext.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = React.createContext();
const baseURL = process.env.REACT_APP_BASE_URL;


const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  async function getUserData() {
    try {
      const response = await axios.get(`${baseURL}/customauth/user/`, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        console.log('SUccess');
      }
      setIsAuthenticated(true);
      setIsCheckingAuth(false);
    } catch (error) {
      setIsAuthenticated(false);
      setIsCheckingAuth(false);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  // Provide getUserData function as part of the context
  return (
    <AuthContext.Provider value={{ isAuthenticated, getUserData, isCheckingAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
