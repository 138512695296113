import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PhotoIcon, UserCircleIcon, Bars4Icon } from '@heroicons/react/24/solid'
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const EditPost = () => {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [blocks, setBlocks] = useState([]);
    const [showAddBlockForm, setShowAddBlockForm] = useState(false);
    const [image, setImage] = useState(null);
    const baseURL = process.env.REACT_APP_BASE_URL;
    const internalBaseURL = process.env.REACT_APP_BASE_URL_INTERNAL;

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    const handleAddBlock = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('block_type', e.target.elements['block-type'].value);
        formData.append('content', e.target.elements['content'].value);

        if (image) {
            formData.append('image', image);
        }

        console.log('Form data:', formData);

        // Send formData to your backend...
        axios.post(`${baseURL}/articles/api/add_block/${postId}/`, formData)
            .then(res => {
                console.log('Block added:', res.data);
                setBlocks([...blocks, res.data]);
                setShowAddBlockForm(false);
            })
            .catch(err => {
                console.error('Failed to add block:', err);
            });

    };

    useEffect(() => {
        if (!post) {
            axios.get(`${baseURL}/articles/api/get_article_by_id/${postId}`)
                .then(res => {
                    setPost(res.data);
                    setBlocks(res.data.blocks);
                    console.log(res.data);
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }, [postId, post]);

    const handleSave = (e) => {
        e.preventDefault();

        // Use the updated blocks from the state
        const updatedBlocks = blocks.map((block, index) => ({
            ...block,
            position: index + 1
        }));

        const updatedPost = {
            ...post,
            article: {
                ...post.article,
                body: updatedBlocks
            }
        };

        console.log('Sending to backend:', updatedPost);

        axios.put(`${baseURL}/articles/api/get_article_by_id_update/${postId}/`, updatedPost)
            .then(res => {
                console.log('Post updated:', res.data);
            })
            .catch(err => {
                console.error('Failed to update post:', err);
            });
    };

    const handleSavePostEdit = (e) => {
        e.preventDefault();

        // Use the updated blocks from the state
        const updatedBlocks = blocks.map((block, index) => ({
            ...block,
            position: index + 1
        }));

        const updatedPost = {
            ...post,
            article: {
                ...post.article,
                body: updatedBlocks,
                status: 'Published' // Setting the status to Published
            }
        };

        console.log('Sending to backend:', updatedPost);

        axios.put(`${baseURL}/articles/api/get_article_by_id_update/${postId}/`, updatedPost)
            .then(res => {
                console.log('Post updated:', res.data);
                window.location.href = '/admin/draftarticles'; // Redirects to /admin/dashboard after a successful save
            })
            .catch(err => {
                console.error('Failed to update post:', err);
            });
    };

    const handleTitleChange = (e) => {
        setPost({ ...post, article: { ...post.article, title: e.target.value } });
    };

    const handleMetaDescriptionChange = (e) => {
        setPost({ ...post, article: { ...post.article, description: e.target.value } });
    };

    const handleBlockChange = (index, content) => {
        const updatedBlocks = [...blocks]; // Clone the current blocks
        updatedBlocks[index].content = content; // Update the content of the specific block
        setBlocks(updatedBlocks); // Set the new blocks state
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newBlocks = Array.from(blocks);
        const [reorderedBlock] = newBlocks.splice(result.source.index, 1);
        newBlocks.splice(result.destination.index, 0, reorderedBlock);

        const updatedBlocks = newBlocks.map((block, index) => ({
            ...block,
            position: index + 1
        }));

        setBlocks(updatedBlocks);
        console.log('Updated Blocks in onDragEnd:', updatedBlocks);

        axios.put(`${baseURL}/articles/api/reordered_blocks/${postId}/`, updatedBlocks)
            .then(res => {
                console.log('Post updated:', res.data);
            })
            .catch(err => {
                console.error('Failed to update post:', err);
            });
    };

    const handleDeleteBlock = (indexToDelete, blockid) => {
        const updatedBlocks = blocks.filter((_, index) => index !== indexToDelete);
        setBlocks(updatedBlocks);

        // Optionally update the backend
        // Update the backend with the new blocks order
        const updatedPost = {
            ...post,
            article: {
                ...post.article,
                body: updatedBlocks
            },
            blockid: blockid,
            type: 'block_delete'
        };
        const type = 'block_delete'
        axios.put(`${baseURL}/articles/api/get_article_by_id_update/${postId}/`, updatedPost)
            .then(res => {
                console.log('Block deleted, post updated:', res.data);
            })
            .catch(err => {
                console.error('Failed to delete block:', err);
            });
    };

    useEffect(() => {
        console.log(showAddBlockForm);
    }, [showAddBlockForm]);

    if (!post) return <div>Loading...</div>;

    return (
        <>

            <form onSubmit={handleSave}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Article Edit</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            During this article it would go into draft, unless you pick save and publish.
                        </p>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                            <button
                                type='button'
                                className="rounded-md bg-yellow-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                style={{ marginRight: '10px' }}
                                onClick={() => window.open(`${internalBaseURL}/admin/${post.article.slug}`, '_blank')}
                            >
                                Preview
                            </button>
                            <button
                                id="ok-btn"
                                type="button"  // This is important for form submission
                                className="rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={handleSave}
                                style={{ marginRight: '10px' }}
                            >
                                Save
                            </button>
                            <button
                                type='button'
                                onClick={handleSavePostEdit}  // Attaching the handleSave function to the onClick event
                                className="rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Save and Publish
                            </button>
                        </div>

                        <button
                            onClick={() => setShowAddBlockForm(true)}
                            type="button"
                            className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Add Block
                        </button>


                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                            <div className="sm:col-span-3">
                                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                                    Article Title
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="title"
                                        id="title"
                                        value={post.article.title}
                                        onChange={handleTitleChange}
                                        autoComplete="title"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>


                            <div className="col-span-full">
                                <label htmlFor="metadescription" className="block text-sm font-medium leading-6 text-gray-900">
                                    Meta Description
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        id="metadescription"
                                        name="metadescription"
                                        rows={3}
                                        value={post.article.description}
                                        onChange={handleMetaDescriptionChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue={''}
                                    />
                                </div>
                                <p className="mt-3 text-sm leading-6 text-gray-600">The title and meta are fixed and as such are not reorderable.</p>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="article-body" className="block text-sm font-medium leading-6 text-gray-900">
                                    Article Body
                                </label>
                                <div className="mt-2">
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable-blocks">
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    {blocks.map((block, index) => {
                                                        // Ensure the draggableId is derived consistently
                                                        const draggableId = `draggable-${block.position}`; // Using index as part of ID for consistency

                                                        return (
                                                            <Draggable key={draggableId} draggableId={draggableId} index={index}>
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        className="mb-4 draggable-item"
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div className="drag-handle" aria-label="Drag Handle">
                                                                            <Bars4Icon className="h-5 w-5 text-gray-500 cursor-move" />
                                                                        </div>

                                                                        {block.block_type === 'paragraph' ? (
                                                                            <textarea
                                                                                id={`article-body-block-${index}`}
                                                                                name={`article-body-block-${index}`}
                                                                                rows={4}
                                                                                value={block.content}
                                                                                onChange={(e) => handleBlockChange(index, e.target.value)}
                                                                                className="block w-full rounded-md border py-1.5 text-gray-900 sm:text-sm sm:leading-6"
                                                                            />
                                                                        ) : null}

                                                                        {block.block_type === 'heading' ? (
                                                                            <textarea
                                                                                id={`article-body-block-${index}`}
                                                                                name={`article-body-block-${index}`}
                                                                                rows={1}
                                                                                value={block.content}
                                                                                onChange={(e) => handleBlockChange(index, e.target.value)}
                                                                                className="block w-full rounded-md border py-1.5 text-gray-900 sm:text-sm sm:leading-6"
                                                                            />
                                                                        ) : null}

                                                                        {block.block_type === 'image' ? (
                                                                            <img
                                                                                src={block.image} // Assuming 'content' contains the image URL
                                                                                alt={`Block Image ${index}`}
                                                                                className="w-auto h-12"
                                                                            />
                                                                        ) : null}

                                                                        <button
                                                                            onClick={() => handleDeleteBlock(index, block.id)}
                                                                            className="rounded bg-red-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                        >
                                                                            Delete
                                                                        </button>

                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                                <p className="mt-3 text-sm leading-6 text-gray-600">Re ordering would also save the article.</p>
                            </div>



                        </div>
                    </div>



                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Save
                    </button>
                </div>
            </form>


            {showAddBlockForm && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <div className="mt-3 text-center">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                {/* Icon or image for the form */}
                            </div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Add Block</h3>
                            {/* The form itself */}
                            <br />
                            <form className="mt-2" onSubmit={handleAddBlock}>
                                {/* Form fields go here */}


                                <br />
                                <div className="relative">
                                    <label htmlFor="block-type"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
                                        Block Type
                                    </label>
                                    <select
                                        id="block-type"
                                        name="block-type"
                                        // value={category}
                                        // onChange={handleCategoryChange}
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option value="">Select a Type</option>
                                        <option value="image">Image</option>
                                        <option value="heading">Heading</option>
                                        <option value="paragraph">Paragraph</option>
                                        {/* {categories.map(category => (
                                            <option key={category.id} value={category.name}>{category.name}</option>
                                        ))} */}
                                    </select>
                                </div>

                                <br />

                                <div className="relative">
                                    <label
                                        htmlFor="content"
                                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                                    >
                                        Content
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            rows={4}
                                            name="content"
                                            id="content"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            defaultValue={''}
                                        />
                                    </div>
                                </div>


                                <br />
                                <div className="relative">
                                    <label htmlFor="block-image" className="block text-sm font-medium text-gray-700">
                                        Image (optional)
                                    </label>
                                    <input
                                        type="file"
                                        name="block-image"
                                        id="block-image"
                                        className="mt-2 block w-full text-sm text-gray-900 file:rounded file:border-0 file:bg-violet-50 file:py-2 file:px-4 file:text-violet-700 file:cursor-pointer hover:file:bg-violet-100"
                                        accept="image/*" // Accept only image files
                                        onChange={handleImageChange} // Handle the image change
                                    />
                                </div>

                                {/* Add more inputs as needed */}
                                <br />
                                <div className="items-center px-4 py-3">
                                    <button
                                        id="ok-btn"
                                        type="submit"  // This is important for form submission
                                        className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                            <div className="items-center px-4 py-3">
                                <button
                                    className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                                    onClick={() => setShowAddBlockForm(false)} // Set isPopupOpen to false when button is clicked
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </>
    );
};

export default EditPost;
