import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { BellIcon } from '@heroicons/react/24/outline'
import HomePage from './Pages/HomePage'
import CP1 from './Pages/ContentPage';
import ContentRenderer from './Components/ContentRenderer';

const AppRoutes = () => {
    let routes = useRoutes([
        { path: "/", element: <HomePage /> },
        { path: "/content1", element: <CP1 /> },
        { path: ":slug", element: <ContentRenderer /> }
        // ... add more routes here
    ]);

    return routes;
};



export default function Base() {
    return (
        <div className="flex min-h-full flex-col">
            <header className="shrink-0 border-b border-gray-200 bg-white">
                <div className="mx-auto flex h-16 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                    <a href="/">
                        <img
                            className="h-8 w-auto"
                            src="./only_logo_nobg.png"
                            alt="Your Company"

                        />
                    </a>
                    <div className="flex items-center gap-x-8">
                        <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-300">
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your profile</span>
                            <img
                                className="h-8 w-8 rounded-full bg-gray-800"
                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt=""
                            />
                        </a>
                    </div>
                </div>
            </header>

            <div className="mx-auto flex w-full max-w-7xl items-start gap-x-8 px-4 py-10 sm:px-6 lg:px-8">
                <AppRoutes />
            </div>
        </div>
    )
}
